import { Component, Injector, OnInit } from '@angular/core';
import { TypeListMenu } from '../../enums/type-list-menu';
import { CompanyParamsService } from '../../services/company-params.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/utils';
import { BaseComponent } from 'src/app/shared/base/base.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  typeListMenu= TypeListMenu;
  constructor(public companyParamsService: CompanyParamsService,private  injector  : Injector,) {
    super(injector);
  }

  ngOnInit(): void {
  }

  goFavorites() {
    const urlFavorites:any =
    this.companyParamsService.getNameUrl() +
    '/wishlist';
    Utils.openNewTab(urlFavorites);
  }



}
