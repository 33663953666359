<app-header></app-header>

<!-- Content -->


<div class="flex flex-col flex-auto" style="
min-height: 36rem;
">

    <!-- 
        <li>
    
            <a routerLink="forms"> forms</a>
        </li>
        <li>
    
            <a routerLink="search"> search</a>
        </li>
        <li>
    
            <a routerLink="search/cormorant"> Cormorant</a>
        </li>
        <li>
    
            <a routerLink="cruise"> Cruise Profile</a>
        </li>
        <li>
    
            <a routerLink="checkout"> checkout</a>
        </li>
    -->
    <router-outlet></router-outlet>

</div>

<app-footer></app-footer>